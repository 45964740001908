<!--
 * @Author: gaojingran
 * @Date: 2021-03-25 10:09:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-01 15:43:17
 * @Description: 区域拖拽上传
⣿⣿⣿⣿⣿⠟⠋⠄⠄⠄⠄⠄⠄⠄⢁⠈⢻⢿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⠃⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠈⡀⠭⢿⣿⣿⣿⣿
⣿⣿⣿⣿⡟⠄⢀⣾⣿⣿⣿⣷⣶⣿⣷⣶⣶⡆⠄⠄⠄⣿⣿⣿⣿
⣿⣿⣿⣿⡇⢀⣼⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣧⠄⠄⢸⣿⣿⣿⣿
⣿⣿⣿⣿⣇⣼⣿⣿⠿⠶⠙⣿⡟⠡⣴⣿⣽⣿⣧⠄⢸⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣾⣿⣿⣟⣭⣾⣿⣷⣶⣶⣴⣶⣿⣿⢄⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⡟⣩⣿⣿⣿⡏⢻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣹⡋⠘⠷⣦⣀⣠⡶⠁⠈⠁⠄⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣍⠃⣴⣶⡔⠒⠄⣠⢀⠄⠄⠄⡨⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣦⡘⠿⣷⣿⠿⠟⠃⠄⠄⣠⡇⠈⠻⣿⣿⣿⣿
⣿⣿⣿⣿⡿⠟⠋⢁⣷⣠⠄⠄⠄⠄⣀⣠⣾⡟⠄⠄⠄⠄⠉⠙⠻
⡿⠟⠋⠁⠄⠄⠄⢸⣿⣿⡯⢓⣴⣾⣿⣿⡟⠄⠄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⠄⠄⣿⡟⣷⠄⠹⣿⣿⣿⡿⠁⠄⠄⠄⠄⠄⠄⠄⠄
-->

<style lang="less" scoped>
.neo-upload-dragger {
  position: relative;
  /deep/.ant-upload.ant-upload-drag {
    background-color: @background-color_4;
    .ant-upload {
      padding: 0;
    }
  }
  .percent-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background-color: @success-color;
    transition: all 0.3s linear;
  }
  .table-delete {
    &:hover {
      color: @error-color;
    }
  }
  .dragger-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
    &.collapsed {
      .details {
        display: flex;
        align-items: center;
        .icon {
          margin-top: 3px;
          margin-right: 5px;
        }
        p {
          text-align: left;
        }
      }
    }
    .details {
      .note {
        .placeholder {
          font-size: @font_size_3;
          color: @text_color_1;
          font-weight: 500;
          margin: 5px 0;
        }
        .sub-holder {
          font-size: @font_size_1;
          color: @text_color_2;
        }
      }
    }
  }
  .dragger-table-title {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: @font_size_3;
    color: @text_color_1;
    font-weight: 500;
  }
  .file-tag-list {
    margin-top: 10px;
    .file-tag-item {
      max-width: 120px;
      cursor: pointer;
      &:hover {
        .icon-close {
          display: block !important;
        }
      }
      .file-name {
        font-size: @font_size_2;
        color: @text_color_2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0;
        line-height: 15px;
      }
      .action-icon {
        text-align: center;
        font-size: @font_size_1;
        color: @text_color_2;
        line-height: 20px;
        .icon-close {
          display: none;
        }
      }
    }
  }
}
</style>

<template>
  <div class="neo-upload-dragger">
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :accept="accept"
      :multiple="multiple"
      :file-list="value"
      :beforeUpload="handleBeforeUpload"
      :disabled="value.length >= maxLength"
      :customRequest="customRequest"
    >
      <div
        class="dragger-container"
        :class="[collapsed ? 'collapsed' : '']"
        :style="{ height: collapsed ? `${shrinkHeight}px` : `${height}px` }"
      >
        <div class="details">
          <div class="icon">
            <NeoIcon name="file" :size="28" />
          </div>
          <div class="note">
            <p class="placeholder">{{ placeholder }}</p>
            <p class="sub-holder">
              {{
                $t(!accept ? 'validate.upload_number_size_all_type' : 'validate.upload_number_size', {
                  number: maxLength,
                  size: maxSize,
                  accept: acceptFormat,
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </a-upload-dragger>
    <!-- tag状态下上传进度条 -->
    <template v-if="showUploadingPercent">
      <div class="percent-bar" :style="{ width: `${uploadPercent}%` }"></div>
    </template>
    <!-- file List 展示方式 Table -->
    <template v-if="value.length && fileType === 'table'">
      <p class="dragger-table-title">{{ $t('uploadDragger.title') }}</p>
      <a-table :data-source="value" :pagination="false" :bordered="false" size="middle" rowKey="uid">
        <a-table-column key="name" :title="$t('uploadDragger.table_filename')">
          <template slot-scope="{ name }">
            <a-icon class="c-2 mr-2" type="paper-clip" />
            <span>{{ name }}</span>
          </template>
        </a-table-column>

        <a-table-column key="percent" width="40%" :title="$t('uploadDragger.table_percent')">
          <template slot-scope="{ percent, status }">
            <a-progress
              :percent="Math.round(percent)"
              :status="status === 'error' ? 'exception' : status === 'uploading' ? 'active' : null"
            />
          </template>
        </a-table-column>

        <a-table-column key="_action" :width="100" :title="$t('uploadDragger.table_action')">
          <template slot-scope="{ status, uid, id }">
            <a-icon
              class="table-delete pointer"
              :class="status === 'error' ? 'error' : 'c-2'"
              type="delete"
              @click.native="handleDeleteFile(uid || id)"
            />
          </template>
        </a-table-column>
      </a-table>
    </template>
    <!-- file List 展示方式 Tag  -->
    <template v-if="value.length && fileType === 'tag'">
      <div class="file-tag-list">
        <a-space :size="10">
          <div class="file-tag-item" v-for="item in value" :key="item.uid">
            <p class="file-name" :class="item.status === 'error' ? 'error' : 'c-2'" :title="item.name">
              {{ item.name }}
            </p>
            <div class="action-icon">
              <a-space :size="10">
                <a-icon v-if="item.status === 'uploading'" type="loading" />
                <a-icon class="icon-close" type="close" @click="handleDeleteFile(item.uid || item.id)" />
              </a-space>
            </div>
          </div>
        </a-space>
      </div>
    </template>
  </div>
</template>

<script>
import withAliOss from '@/mixins/withAliOss'

export default {
  name: 'NeoUploadDragger',
  mixins: [withAliOss],
  props: {
    /**
     *  文件列表 object[]
     *  uid
        name      // 文件名
        status    // 状态有：uploading done error
        percent   // 上传进度
        url       // 下载链接
     * */
    value: {},
    // 数据排序方式
    sortType: {
      type: String,
      default: 'push',
      validator(value) {
        return ['push', 'unshift'].indexOf(value) !== -1
      },
    },
    // 上传区域height
    height: {
      type: Number,
      default: 330,
    },
    // 上传区域收缩后height
    shrinkHeight: {
      type: Number,
      default: 98,
    },
    // 是否启用上传后收缩height
    collapsible: {
      type: Boolean,
      default: true,
    },
    // 默认状态  true 收起 false 展开
    defaultCollapsed: {
      type: Boolean,
      default: false,
    },
    // 文件展示类型 table & tag
    fileType: {
      type: String,
      default: 'table',
      validator(value) {
        return ['table', 'tag'].indexOf(value) !== -1
      },
    },
    // placeholder
    placeholder: {
      type: String,
      default() {
        return this.$t('uploadDragger.upload_translation_file')
      },
    },
    // 接受上传的文件类型 https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
    accept: {
      type: String,
      default: '',
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 最大多少文件
    maxLength: {
      type: Number,
      default: 5,
    },
    // 单文件大小 单位 M
    maxSize: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      collapsed: false,
      show: 0,
    }
  },
  computed: {
    containerHeight() {
      return this.collapsed ? this.shrinkHeight : this.height
    },
    acceptFormat() {
      if (this.accept) {
        return this.accept
          .split(',')
          .map((v) => `*${v}`)
          .join(', ')
      }
      return '---'
    },
    // tag展示方式下percent进度
    showUploadingPercent() {
      if (this.fileType === 'tag' && this.value.find((v) => v.status === 'uploading')) {
        return true
      }
      return false
    },
    uploadPercent() {
      if (this.value.length) {
        const uploading = this.value.filter((v) => v.status === 'uploading')
        if (uploading.length) {
          const percent = uploading.reduce((acc, val) => {
            return acc + val.percent
          }, 0)
          const total = uploading.length * 100
          return (percent / total) * 100 || 0
        } else {
          return 0
        }
      }
      return 0
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.collapsible) {
          this.collapsed = val.length > 0
        } else {
          this.collapsed = this.defaultCollapsed
        }
      },
    },
    show(v) {
      if (v == 1) {
        this.$message.error(this.$t('newOrder.size_max_20'))
      } else if (v == 2) {
        this.$message.error(this.$t('newOrder.size_max_20'))
      }
    },
  },
  methods: {
    // beforeUpload
    handleBeforeUpload(file, fileList) {
      const { name, size } = file
      if (this.accept) {
        const suffix = name.slice(name.lastIndexOf('.'))
        if (
          this.accept
            .split(',')
            .map((v) => v.trim())
            .indexOf(suffix) < 0
        ) {
          this.$message.error(this.$t('prompt.upload_illegal_format', { name }))
          return false
        }
      }
      if (size / 1024 / 1024 > this.maxSize) {
        this.$message.error(this.$t('validate.upload_max_size', { filename: name, size: this.maxSize }))
        return false
      }

      if (this.maxLength) {
        if (fileList.length > 20) {
          this.show = 1
          return false
        }

        if (this.value.length + fileList.length > 20) {
          this.show = 2
          return false
        }
      }
    },
  },
}
</script>
