var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"neo-upload-dragger"},[_c('a-upload-dragger',{attrs:{"name":"file","showUploadList":false,"accept":_vm.accept,"multiple":_vm.multiple,"file-list":_vm.value,"beforeUpload":_vm.handleBeforeUpload,"disabled":_vm.value.length >= _vm.maxLength,"customRequest":_vm.customRequest}},[_c('div',{staticClass:"dragger-container",class:[_vm.collapsed ? 'collapsed' : ''],style:({ height: _vm.collapsed ? (_vm.shrinkHeight + "px") : (_vm.height + "px") })},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"icon"},[_c('NeoIcon',{attrs:{"name":"file","size":28}})],1),_c('div',{staticClass:"note"},[_c('p',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.placeholder))]),_c('p',{staticClass:"sub-holder"},[_vm._v(" "+_vm._s(_vm.$t(!_vm.accept ? 'validate.upload_number_size_all_type' : 'validate.upload_number_size', { number: _vm.maxLength, size: _vm.maxSize, accept: _vm.acceptFormat, }))+" ")])])])])]),(_vm.showUploadingPercent)?[_c('div',{staticClass:"percent-bar",style:({ width: (_vm.uploadPercent + "%") })})]:_vm._e(),(_vm.value.length && _vm.fileType === 'table')?[_c('p',{staticClass:"dragger-table-title"},[_vm._v(_vm._s(_vm.$t('uploadDragger.title')))]),_c('a-table',{attrs:{"data-source":_vm.value,"pagination":false,"bordered":false,"size":"middle","rowKey":"uid"}},[_c('a-table-column',{key:"name",attrs:{"title":_vm.$t('uploadDragger.table_filename')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.name;
return [_c('a-icon',{staticClass:"c-2 mr-2",attrs:{"type":"paper-clip"}}),_c('span',[_vm._v(_vm._s(name))])]}}],null,false,2826712377)}),_c('a-table-column',{key:"percent",attrs:{"width":"40%","title":_vm.$t('uploadDragger.table_percent')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var percent = ref.percent;
var status = ref.status;
return [_c('a-progress',{attrs:{"percent":Math.round(percent),"status":status === 'error' ? 'exception' : status === 'uploading' ? 'active' : null}})]}}],null,false,4065275091)}),_c('a-table-column',{key:"_action",attrs:{"width":100,"title":_vm.$t('uploadDragger.table_action')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var uid = ref.uid;
var id = ref.id;
return [_c('a-icon',{staticClass:"table-delete pointer",class:status === 'error' ? 'error' : 'c-2',attrs:{"type":"delete"},nativeOn:{"click":function($event){return _vm.handleDeleteFile(uid || id)}}})]}}],null,false,4188059897)})],1)]:_vm._e(),(_vm.value.length && _vm.fileType === 'tag')?[_c('div',{staticClass:"file-tag-list"},[_c('a-space',{attrs:{"size":10}},_vm._l((_vm.value),function(item){return _c('div',{key:item.uid,staticClass:"file-tag-item"},[_c('p',{staticClass:"file-name",class:item.status === 'error' ? 'error' : 'c-2',attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"action-icon"},[_c('a-space',{attrs:{"size":10}},[(item.status === 'uploading')?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e(),_c('a-icon',{staticClass:"icon-close",attrs:{"type":"close"},on:{"click":function($event){return _vm.handleDeleteFile(item.uid || item.id)}}})],1)],1)])}),0)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }