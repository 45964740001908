<!--
 * @Author: gaojingran
 * @Date: 2021-04-07 16:29:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-02 15:02:53
 * @Description: 创建订单
-->
<style lang="less" scoped>
.new-order {
  padding: 10px 20px 20px;
  /deep/.ant-form-item {
    display: flex;
  }
  .button-wrapper {
    padding-left: 80px;
  }
  .wait-trans-file-link {
    /deep/.ant-form-item-label {
      flex: 0 0 103px !important;
    }
  }
}
// .task-type {
//   /deep/ .ant-select-selection__clear {
//     display: '';
//   }
// }
</style>

<template>
  <NeoPageLayout>
    <a-spin :spinning="loading">
      <div class="new-order">
        <h1 class="mt-4 mb-4 fs-6">{{ $t('newOrder.title') }}</h1>
        <template>
          <div class="order-form">
            <a-form-model ref="orderForm" v-bind="formLayout" :colon="false" :model="form" :rules="rules">
              <a-row :gutter="20">
                <a-col :span="12" flex="auto">
                  <a-form-model-item
                    require
                    :label="$t('newOrder.wait_trans_file_link')"
                    class="wait-trans-file-link _mb-0"
                    prop="customizedOrderFileUrlRequest.fileUrl"
                  >
                    <a-input
                      allowClear
                      autocomplete="off"
                      v-if="form.customizedOrderFileUrlRequest"
                      v-model="form.customizedOrderFileUrlRequest.fileUrl"
                      :placeholder="$t('newOrder.wait_trans_file_link_holder')"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item required :label="$t('newOrder.task_type')">
                    <a-row type="flex">
                      <a-col flex="auto">
                        <a-form-model-item prop="taskType" :wrapperCol="{ span: 24 }" class="_mb-0">
                          <a-select
                            allowClear
                            v-model="form.taskType"
                            :placeholder="$t('newOrder.task_type_holder')"
                            class="task-type"
                          >
                            <a-select-option v-for="item in taskTypes" :key="item.id" :value="item.id">
                              {{ item.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>

                      <a-col flex="163px">
                        <a-form-model-item class="_mb-0">
                          <a-checkbox v-model="form.cmReview" class="_ml-25"> CM/100% Review </a-checkbox>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="name" :label="$t('newOrder.order_name')">
                    <a-input
                      allowClear
                      autocomplete="off"
                      v-model="form.name"
                      :placeholder="$t('newOrder.order_name_holder_customized')"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="productLineId" :label="$t('newOrder.product_line')">
                    <a-select allowClear v-model="form.productLineId" :placeholder="$t('newOrder.product_line_holder')">
                      <a-select-option v-for="item in productLineList" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="sourceCode" :label="$t('newOrder.source')">
                    <a-select
                      allowClear
                      show-search
                      option-filter-prop="children"
                      v-model="form.sourceCode"
                      :filter-option="langFilterOption"
                      :placeholder="$t('newOrder.source_holder')"
                    >
                      <a-select-option
                        v-for="item in sourceCodeList"
                        :key="item.code"
                        :value="item.code"
                        :disabled="form.targetCodeList.includes(item.code)"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="targetCodeList" :label="$t('newOrder.target')">
                    <a-select
                      allowClear
                      mode="multiple"
                      :maxTagTextLength="20"
                      :token-separators="[',']"
                      v-model="form.targetCodeList"
                      @change="handleTargetCodeChange"
                      :filter-option="langFilterOption"
                      :placeholder="$t('newOrder.target_holder')"
                    >
                      <a-select-option
                        v-for="item in targetCodeList"
                        :key="item.code"
                        :value="item.code"
                        :disabled="form.sourceCode === item.code"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item required :label="$t('newOrder.service_type')" style="margin-bottom: 0">
                    <a-row type="flex">
                      <a-col flex="auto">
                        <a-form-model-item prop="serviceType" :wrapperCol="{ span: 24 }">
                          <a-select
                            allowCler
                            v-model="form.serviceType"
                            :placeholder="$t('newOrder.service_type_holder')"
                          >
                            <a-select-option v-for="item in serviceTypeList" :key="item.id" :value="item.id">
                              {{ item.label }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>

                      <a-col flex="150px">
                        <a-form-model-item class="fr" prop="fine" :wrapperCol="{ span: 24 }">
                          <a-checkbox v-model="form.composeType">
                            {{ $t('newOrder.fine') }}
                          </a-checkbox>
                          <a-tooltip placement="top" :title="$t('newOrder.fine_tip')">
                            <a-icon type="question-circle" />
                          </a-tooltip>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>
                <!-- 供应商 -->
                <a-col :span="12">
                  <a-form-model-item required prop="supplierId" :label="$t('newOrder.supplier')">
                    <a-select
                      showArrow
                      allowClear
                      v-model="form.supplierId"
                      :placeholder="$t('newOrder.supplier_holder')"
                    >
                      <a-select-option v-for="item in supplierList" :key="item.supEntityId" :value="item.supEntityId">
                        {{ item.supEntityName }}
                        <span v-if="item.isRecommend"><img src="@/assets/image/recommend.png" /> </span>
                      </a-select-option>
                    </a-select>
                    <div style="color: red; line-height: 25px" v-if="supplierTipShow2 && supplierTipShow3">
                      {{
                        $t('validate.supplier_info', {
                          s: getCodeName(form.sourceCode),
                          t: getCodesName(invalidTargetCodeList),
                          documentType: getDocTypeName(form.documentType),
                          serviceType: getTypeName(form.serviceType),
                        })
                      }}
                    </div>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="documentType" :label="$t('newOrder.doc_type')">
                    <a-select allowClear v-model="form.documentType" :placeholder="$t('newOrder.doc_type_holder')">
                      <a-select-option v-for="item in documentTypeList" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="dueDate" :label="$t('newOrder.delivery_date')">
                    <a-date-picker
                      style="width: 100%"
                      allowCler
                      valueFormat="YYYY-MM-DD HH:mm:00"
                      v-model="form.dueDate"
                      :showTime="timePickerOption"
                      :format="$t('dateFormat.a')"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item prop="remark" :label="$t('newOrder.order_note')">
                    <a-textarea
                      style="width: 100%"
                      v-model="form.remark"
                      :placeholder="$t('newOrder.order_note_holder')"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                      @blur="handleInputBlur($event, 'remark')"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item prop="attachment" style="margin-bottom: 0">
                    <div style="padding-left: 80px">
                      <NeoUploadDragger
                        fileType="tag"
                        v-model="form.attachmentFileList"
                        sortType="unshift"
                        :placeholder="$t('uploadDragger.upload_attachment_file')"
                        :accept="acceptMap.orderAttachmentFile"
                        :defaultCollapsed="true"
                        :collapsible="false"
                        :maxLength="5"
                        :maxSize="10"
                      />
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <!-- button -->
            <div class="button-wrapper" :class="!form.attachmentFileList.length ? 'mt-4' : 'mt-1'">
              <a-space :size="10">
                <a-button class="neo-btn-primary" @click="handleSaveDraft">
                  {{ $t('newOrder.save_draft') }}
                </a-button>
                <!-- <a-button type="primary" :disabled="true">{{ $t('newOrder.auto_quote') }}</a-button> -->
                <a-button type="primary" @click="handleGetQuote">
                  {{ $t('newOrder.get_quote') }}
                </a-button>
                <NeoTips :label="$t('newOrder.note_label')" :text="$t('newOrder.note_desc')" />
              </a-space>
            </div>
          </div>
        </template>
      </div>
    </a-spin>
  </NeoPageLayout>
</template>

<script>
import { mapState } from 'vuex'
import { Icon } from 'ant-design-vue'
import NeoUploadDragger from '@/components/upload/NeoUploadDragger'
import NeoTips from '@/components/common/NeoTips'
import { uuid } from '@/utils/utils'

const { validate, acceptMap } = window.$g

export default {
  name: 'RockwellNewOrder',
  components: {
    NeoUploadDragger,
    NeoTips,
  },
  data() {
    return {
      // 供应商列表
      supplierList: [],
      loading: false,
      acceptMap,
      formLayout: {
        labelCol: { flex: '80px' },
        wrapperCol: { flex: 'auto' },
      },
      timePickerOption: {
        format: 'HH:mm',
      },
      // 产线
      productLineList: [],
      // 翻译文件
      sourceFileList: [],
      // 订单表单
      form: {
        customizedOrderFileUrlRequest: {
          fileUrl: '',
          type: 1, //源语文件
        },
        productLineId: undefined,
        sourceCode: undefined,
        targetCodeList: [],
        name: '',
        dueDate: undefined,
        serviceType: undefined,
        documentType: undefined,
        composeType: false, // 排版类型 0-简排 1-精排
        attachmentFileList: [],
        remark: '',
        supplierId: undefined,
        cmReview: false,
        taskType: '',
      },
      // 表单验证
      rules: {
        'customizedOrderFileUrlRequest.fileUrl': [validate.required],
        productLineId: [validate.required],
        dueDate: [validate.earlier_time],
        sourceCode: [validate.required],
        targetCodeList: [validate.required],
        name: [{ ...validate.required, trigger: 'blur' }, validate.length_3_50],
        documentType: [validate.required],
        serviceType: [validate.required],
        remark: [validate.size_1000],
        supplierId: [validate.required],
        taskType: [validate.required],
      },
      // -----------------
      serviceTypeList: [],
      documentTypeList: [],
      // ------
      sourceCodeList: [],
      targetCodeList: [],
      targetCodeList0: [],
      validList: [],
      supPairMap: [],
      sourceCodeListInit: [],
      targetCodeListInit: [],
      mappingLangPair: [],
      supplierTipShow: false,
      supplierTipShow3: true,
      taskTypes: [
        {
          id: 1,
          name: 'MTPE',
        },
        {
          id: 2,
          name: 'TEP',
        },
      ],
    }
  },
  computed: {
    // 步骤一按钮禁用状态
    setpOneButtonDisable() {
      // 当文件不存在合法 以及 存在正在上传的文件
      const validFile = this.sourceFileList.find((v) => v.status === 'uploading')
      return this.$is.Defined(validFile)
    },

    // 语言列表
    ...mapState('app', {
      langList: (state) => state.langList,
      userInfo: (state) => state.userInfo,
    }),
    entityId() {
      return this.$store.state.app.userInfo.agencyId
    },
    supplierInfo() {
      const f = this.form
      const result = !!(
        f.serviceType &&
        f.supplierId &&
        f.sourceCode &&
        f.targetCodeList.length &&
        this.supplierTipShow
      )
      return result
    },
    invalidTargetCodeList() {
      const f = this.form
      const needShowCheck = !!(
        f.serviceType &&
        f.documentType &&
        f.supplierId &&
        f.sourceCode &&
        f.targetCodeList.length
      )
      console.log('needShowCheck :>> ', needShowCheck)
      if (!needShowCheck) return []

      const validPair = this.supPairMap[f.supplierId]
      if (!validPair) return [...f.targetCodeList]

      const validTarget = validPair
        .filter((p) => p.serviceId === f.serviceType && p.documentId === f.documentType)
        .filter((p) => p.sourceCode === f.sourceCode)
        .filter((p) => f.targetCodeList.includes(p.targetCode))
        .map((p) => p.targetCode)

      return f.targetCodeList.filter((t) => !validTarget.includes(t))
    },
    supplierTipShow2() {
      return this.invalidTargetCodeList.length > 0
    },
  },
  watch: {
    'form.productLineId': {
      deep: true,
      handler() {
        this.getSupList()
      },
    },
    'form.sourceCode': {
      deep: true,
      handler(code) {
        console.error(2)
        console.log('code:', code)
        if (code) {
          // item[0]=>obj  item[1]=>obj_arr
          // 修改目标语言下拉列表
          console.log(`this.mappingLangPair`, this.mappingLangPair)
          this.$g.watchVal(this, 'mappingLangPair', () => {
            const target = (this.mappingLangPair.find((item) => {
              return item[0].code === code
            }) || [])[1]
            this.targetCodeList = target
          })
        } else {
          console.log('源语言被清空')
          this.targetCodeList = this.targetCodeListInit
        }
        this.getSupList()
        this.updateSupMatchTip()
      },
    },
    'form.targetCodeList': {
      deep: true,
      handler(codes) {
        if (codes.length) {
          // 修改目标语言下拉列表
          console.log('this.mappingLangPair :>> ', this.mappingLangPair)
          const target = this.mappingLangPair.filter((item) => {
            // console.log('---------↓---------')
            // console.log(`item`, item)
            const codes1 = item[1].map((_item) => _item.code)
            const result = this.$g.includes(codes, codes1)
            // console.log(`codes`, codes)
            // console.log(`codes1`, codes1)
            // console.log(`result`, result)
            // console.log('---------↑---------')
            return result
          })
          let _target = []
          if (target.length) {
            _target = target.map((item) => {
              return item[0]
            })
          }
          console.log(`_target`, _target)
          this.sourceCodeList = _target
        } else {
          this.sourceCodeList = this.sourceCodeListInit
          console.log('目标语言被清空')
        }
        this.getSupList()
        this.updateSupMatchTip()
      },
    },
    'form.supplierId': {
      deep: true,
      handler() {
        this.updateSupMatchTip()
        this.updateSupplierTipShow3()
      },
    },
    'form.serviceType': {
      deep: true,
      handler() {
        this.updateSupplierTipShow3()
      },
    },
    'form.documentType': {
      deep: true,
      handler() {
        this.updateSupplierTipShow3()
      },
    },
    productLineList(arr) {
      if (arr.length === 1) {
        this.form.productLineId = arr[0].id
      }
    },
    supplierList(arr) {
      if (arr.length === 1) {
        this.form.supplierId = arr[0].supEntityId
      }
    },
  },
  mounted() {
    this.fetchAll()
  },
  methods: {
    async updateSupplierTipShow3() {
      const arr = this.serviceTypeList
        .filter((_) => _.value == 1)
        .flat()
        .map((_) => _.id)

      if (arr.includes(this.form.serviceType)) {
        this.supplierTipShow3 = true
        return
      }

      try {
        if (!this.form.supplierId) {
          this.supplierTipShow3 = false
          return
        }

        const params = { supEntityId: this.form.supplierId }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('getPriceListBySupId', params)
        console.error(`data`, data)
        const noEmpty = (a, b) => {
          const empty = ['', null, undefined]

          return empty.includes(a) || empty.includes(b)
        }

        const { serviceType, documentType } = this.form

        const data2 = data.filter((item) => {
          return item.documentId === documentType && item.serviceId === serviceType
        })
        console.error('data2 :>> ', data2)

        const isHide = data2.some((item) => noEmpty(item.sourceCode, item.targetCode))
        console.error('isHide :>> ', isHide)
        if (isHide) {
          this.supplierTipShow3 = false
        } else {
          this.supplierTipShow3 = true
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async fetchAll() {
      const hasId = this.$is.Defined(this.$route.query.id)
      this.getCusProductLineList()
      this.getCusServiceTypeList()
      this.getCusDocumentTypeList()
      // 获取供应商列表
      this.getSupList()

      if (hasId) {
        await this.getInfo()
        this.getOrderPairInfo(hasId)
      } else {
        await this.orderTemp()
        this.getOrderPairInfo(hasId)
      }
    },
    updateSupMatchTip() {
      const supplierId = this.form.supplierId
      const currentLangPair = this.form.sourceCode + '-' + this.form.targetCodeList.join(',')
      if (!(this.form.sourceCode && this.form.targetCodeList.length && supplierId)) {
        this.supplierTipShow = false
        return
      }
      const supPairArr = this.$g.flatten(
        Object.keys(this.supPairMap)
          .map((id) => {
            if (id === supplierId) {
              const str_arr = this.supPairMap[id].map(({ sourceCode, targetCode }) => {
                return sourceCode + '-' + targetCode
              })
              return str_arr
            }
          })
          .filter(Boolean)
      )
      if (!supPairArr.length || supPairArr.excludes(currentLangPair)) {
        this.supplierTipShow = true
      } else {
        this.supplierTipShow = false
      }
    },
    getCodeName(code = '') {
      return this.$store.getters['app/getLangNameByCode'](code) || '---'
    },
    getCodesName(codes = []) {
      const result = codes.map((code) => {
        return this.$store.getters['app/getLangNameByCode'](code)
      })
      return result || '---'
    },
    getTypeName(id = '') {
      return this.serviceTypeList.find((item) => item.id === id)?.label
    },
    getDocTypeName(id = '') {
      return this.documentTypeList.find((item) => item.id === id)?.label
    },
    handleLangPairData(validList) {
      const arrs = this.$g.sortClass(validList, 'sourceCode')
      console.log('arrs :>> ', arrs)
      const mappingLangPair = arrs.map((arr) => {
        // arr的每个sourceCode都是一样的，只有targetCode不一样
        // 输出的格式：[{code:'zh_CN'...},[{code:jp_JP...},{name:en_US...}] ]
        const sourceCode = this.langList.find((item) => item.code === arr[0].sourceCode)
        const targetCodes = arr
          .map((a) => a.targetCode)
          .filter(Boolean)
          .map((code) => {
            const _item = this.langList.find((item) => item.code === code)
            return _item
          })

        console.log('targetCodes :>> ', targetCodes)

        const item = [sourceCode, targetCodes]
        return item
      })
      const sourceCodeListInit = mappingLangPair.map((item) => item[0])
      const targetCodeListInit = this.$g.flatten(mappingLangPair.map((item) => item[1]))
      console.log('targetCodeListInit :>> ', targetCodeListInit)
      this.mappingLangPair = mappingLangPair
      console.log('this.mappingLangPair :>> ', this.mappingLangPair)
      this.sourceCodeListInit = sourceCodeListInit
      this.targetCodeListInit = targetCodeListInit

      return { sourceCodeListInit, targetCodeListInit }
    },
    async getOrderPairInfo(hasId) {
      try {
        console.log('hasId :>> ', hasId)
        this.loading = true
        const data = await this.$http('getOrderPairInfo')
        let sourceCodeList = []
        let targetCodeList = []
        const { validList, supPairMap } = data
        this.validList = validList
        this.supPairMap = supPairMap
        if (validList.length) {
          const { sourceCodeListInit, targetCodeListInit } = this.handleLangPairData(validList)
          console.log(targetCodeListInit)
          sourceCodeList = sourceCodeListInit
          targetCodeList = targetCodeListInit
        } else {
          sourceCodeList = this.langList
          targetCodeList = this.langList
        }
        console.log('targetCodeList :>> ', targetCodeList)
        this.sourceCodeList = sourceCodeList
        this.targetCodeList = targetCodeList

        // 如果是新下的单子  =>  预赋值源语言和目标语言 en_US
        if (!hasId) {
          const s_codes = sourceCodeList.map((_) => _.code)
          // eslint-disable-next-line no-unused-vars
          const t_codes = targetCodeList.map((_) => _.code)

          const supplierList = await this.getSupList()
          // 如果供应商列表为空不许自动添加源目标的语言
          if (!supplierList.length) {
            console.log('供应商列表为空')
          } else {
            console.warn('this.targetCodeList0 :>> ', this.targetCodeList0)
            if (s_codes.includes('en_US')) {
              if (!this.targetCodeList0.length) {
                // 获取对应'en_US'的目标语言并赋值
                this.form.sourceCode = 'en_US'

                const target = (this.mappingLangPair.find((item) => {
                  return item[0].code === 'en_US'
                }) || [])[1]
                console.log('target :>> ', target)
                const Codes = this.$g.lang
                console.log('Codes :>> ', Codes)
                const _t_codes = target.filter((_) => Codes.includes(_.code)).map((_) => _.code)
                console.log('_t_codes :>> ', _t_codes)
                this.form.targetCodeList = _t_codes
              } else {
                console.log('上一次有值！')
              }
            } else {
              console.log('源语言无en_US')
            }
          }
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 客户产品线表-列表
    async getCusProductLineList() {
      try {
        const data = await this.$http('cusProductLineList', {
          entityId: this.entityId,
        })
        this.productLineList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户服务类型表-列表
    async getCusServiceTypeList() {
      try {
        const data = await this.$http('cusMaterialList', {
          entityId: this.entityId,
          type: 1,
        })
        this.serviceTypeList = data
        console.log('data :>> ', data)
        // this.serviceTypeList = data.filter(({ value }) => Number(value) === 1)
        this.form.serviceType = this.serviceTypeList.find((_) => _.label === '翻译').id
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户文档类型表-列表
    async getCusDocumentTypeList() {
      try {
        const data = await this.$http('cusMaterialList', {
          entityId: this.entityId,
          type: 2,
        })
        this.documentTypeList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // -----------------↑↑↑---------------------
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleInputBlur(ev, key) {
      this.form[key] = ev.target.value
    },

    getFormData() {
      return {
        ...this.form,
        cmReview: this.form.cmReview ? 1 : 0,
        targetCodeList: this.form.targetCodeList.join(','),
        composeType: Number(this.form.composeType),
        sourceFileList: this.sourceFileList
          .filter((v) => v.status === 'done')
          .map((v) => ({
            name: v.name,
            url: v.url,
          })),
        taskType: this.form.taskType || '',
        // attachmentFileList: this.form.attachmentFileList
        //   .filter((v) => v.status === 'done')
        //   .map((v) => ({
        //     name: v.name,
        //     url: v.url,
        //   })),
      }
    },

    handleTargetCodeChange() {
      const newArray = []
      const errorArray = []
      this.form.targetCodeList.forEach((code) => {
        const n = this.langList.find((v) => v.name === code)
        const c = this.langList.find((v) => v.code === code)
        if (c) {
          return newArray.push(code)
        }
        if (n) {
          // 假设code为name的话替换为code
          return newArray.push(n.code)
        }
        return errorArray.push(code)
      })
      this.form.targetCodeList = newArray
      if (errorArray.length) {
        this.$message.error(
          this.$t('newOrder.error_lang_tips', {
            language: errorArray.join(', '),
          })
        )
      }
    },
    async orderTemp() {
      try {
        const data = await this.$http('orderTemp')
        console.warn('orderTemp---')
        console.error(`data`, data)
        if (data) {
          const {
            productLineId,
            sourceCode,
            targetCodeList,
            name,
            dueDate,
            serviceType,
            documentType,
            composeType,
            sourceFileList,
            remark,
            supplierId,
            customizedOrderFileUrlRequest,
            cmReview: _cmReview,
            // eslint-disable-next-line no-unused-vars
            taskType,
          } = data
          const cmReview = _cmReview ? true : false

          console.log('sourceCode :>> ', sourceCode)

          this.sourceFileList = sourceFileList || []
          this.targetCodeList0 = targetCodeList
          console.log('customizedOrderFileUrlRequest :>> ', customizedOrderFileUrlRequest)
          const { type, fileUrl } = customizedOrderFileUrlRequest

          console.error('this.supplierList :>> ', this.supplierList)

          this.form = {
            productLineId: productLineId || undefined,
            targetCodeList: targetCodeList ? targetCodeList.split(',') : [],
            name: name || undefined,
            sourceCode: sourceCode || undefined,
            dueDate: dueDate || undefined,
            serviceType: serviceType || undefined,
            documentType: documentType || undefined,
            remark: remark || undefined,
            supplierId,
            composeType: Boolean(composeType),
            customizedOrderFileUrlRequest: {
              fileUrl,
              type,
            },
            cmReview,
            taskType,
            attachmentFileList: this.form.attachmentFileList,
          }
          console.error(1)
          console.log('this.form.sourceCode :>> ', this.form.sourceCode)
          this.loading = false
          console.error('this.form :>> ', this.form)
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取草稿箱详情
    async getInfo() {
      try {
        this.loading = true

        const data = await this.$http({
          key: 'orderProcessInfo',
          params: {
            id: this.$route.query.id,
          },
        })
        const {
          id,
          productLineId,
          sourceCode,
          targetCodeList,
          name,
          dueDate,
          serviceType,
          documentType,
          composeType,
          attachmentFileList,
          sourceFileList,
          remark,
          supplierId,
          orderFileUrlDTOList: customizedOrderFileUrlRequest,
          cmReview: _cmReview,
          taskType,
        } = data
        const cmReview = _cmReview ? true : false

        console.warn(`data`, data)
        console.log('remark :>> ', remark)
        console.log('attachmentFileList :>> ', attachmentFileList)
        // console.log(`name`, name)
        this.sourceFileList = sourceFileList.map((v) => ({
          uid: uuid(),
          name: v.name,
          status: 'done',
          percent: 100,
          url: v.url,
        }))
        const { type, fileUrl } = customizedOrderFileUrlRequest[0]

        console.error('this.supplierList :>> ', this.supplierList)

        const call = () => {
          if (!this.supplierList.length) {
            setTimeout(() => {
              call()
            })
          } else {
            this.form = {
              id,
              productLineId: productLineId || undefined,
              sourceCode: sourceCode || undefined,
              targetCodeList: targetCodeList ? targetCodeList.split(',') : [],
              name: name || undefined,
              dueDate: dueDate || undefined,
              serviceType: serviceType || undefined,
              documentType: documentType || undefined,
              remark: remark || undefined,
              supplierId: this.supplierList.map((_) => _.supEntityId).includes(supplierId) ? supplierId : undefined,
              composeType: Boolean(composeType),
              attachmentFileList,
              customizedOrderFileUrlRequest: {
                fileUrl,
                type,
              },
              cmReview,
              taskType,
            }
            this.loading = false
            console.error('this.form :>> ', this.form)
          }
        }

        call()

        // : attachmentFileList
        //     .filter((v) => v.tag === 1)
        //     .map((v) => ({
        //       uid: uuid(),
        //       name: v.name,
        //       status: 'done',
        //       percent: 100,
        //       url: v.url,
        //     }))
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 保存草稿箱
    async handleSaveDraft() {
      try {
        this.loading = true
        await this.$http('processSave', this.getFormData())
        this.loading = false
        this.$success({
          title: this.$t('prompt.save_success'),
          icon: (h) => h(Icon, { props: { type: 'check-circle', theme: 'filled' } }),
          okText: this.$t('confirm'),
          onOk: () => {
            this.canLeave = true
            // 草稿箱
            this.$router.push({
              name: 'order_draftBox',
            })
          },
        })
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取报价
    handleGetQuote() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: this.$t('newOrder.get_quote'),
            content: this.$t('newOrder.get_quote_desc'),
            icon: (h) =>
              h(Icon, {
                props: { type: 'exclamation-circle', theme: 'filled' },
              }),
            okText: this.$t('confirm'),
            cancelText: this.$t('cancel'),
            onOk: async () => {
              try {
                const id = await this.$http('processPresent', this.getFormData())
                this.canLeave = true
                // 跳订单详情
                this.$router.push({
                  path: this.$g.route.order_orderInfo,
                  query: {
                    id,
                    extraName: this.form.name,
                  },
                })
              } catch (err) {
                this.$httpNotify(err)
              }
            },
          })
        }
      })
    },
    // 获取供应商列表
    async getSupList() {
      try {
        const { sourceCode, targetCodeList, productLineId: supProductLineId } = this.form
        this.supplierList = await this.$http('getSupList', {
          sourceCode,
          targetCodeList,
          supProductLineId,
        })
        return this.supplierList
      } catch (err) {
        this.$httpNotify(err)

        console.error(err)
        return this.supplierList
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canLeave && to.name !== 'login') {
      this.$confirm({
        title: this.$t('newOrder.prompt_save_draft'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onCancel: () => {
          next()
        },
        onOk: async () => {
          try {
            await this.$http('processSave', this.getFormData())
            this.canLeave = true
            next('/order/draftBox')
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    } else {
      next()
    }
  },
}
</script>
